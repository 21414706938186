// [ CORES - COLORIDAS ]
$cor-1: #FA2D32;
$cor-2: #F79633;
$cor-3: #28a745;
$cor-4: #353132;
$cor-texto-1: $cor-4;
// [ CORES - ESCALA CINZA ]
$branco: #fff;
$preto: #000;
$cor-01: tint($preto, 0%);
$cor-02: tint($preto, 20%);
$cor-03: tint($preto, 40%);
$cor-04: tint($preto, 60%);
$cor-05: tint($preto, 80%);
$cor-06: tint($preto, 100%);
// [ FONTES - DEFINIÇÕES ] 
$fonte-1: 'Raleway',
sans-serif;
// [ SCREENS - DEFINIÇÕES ]
// desktop
$desktop-f: max-width 1920px;
$desktop-l: max-width 1600px;
$desktop-m: max-width 1440px;
$desktop-s: max-width 1366px;
// tablet
$tablet-l: max-width 1280px;
$tablet-m: max-width 1024px;
$tablet: max-width 960px;
$tablet-s: max-width 768px;
// mobile
$mobile-l: max-width 720px;
$mobile-m: max-width 414px;
$mobile-s: max-width 375px;
// another
$layout: 1440px;
$mobile: max-width 480px;
$mobile_grande: max-width 768px;
$tablet_grande: max-width 1024px;
$wide: max-width 1200px;
$wide_grande: max-width 1440px;
$nomargin: (columns: 12, gutters: 0);
$sumome: 500px $layout;

.layout {
    //full retirar os pxs do container
    //@include container(1400px);
    width: 1300px;
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 0 15px;
}

.desktop {
    @include breakpoint($tablet_grande) {
        display: none !important;
    }
}

.tablet {
    display: none !important;

    @include breakpoint($tablet_grande) {
        display: inline-block !important;
    }
}

.mobile {
    display: none !important;

    @include breakpoint($mobile_grande) {
        display: inline-block !important;
    }
}

.hidden,
.esconder,
.oculto {
    display: none;
}

.clearfix,
.center:after {
    clear: both;
    height: 0;
}

html,
body {
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-family: $fonte-1;
    color: $cor-texto-1;
    line-height: 22px;
    background: $branco;
}

main {
    display: block;
    @include clearfix;
}