.titulo {
    font-family: $fonte-1;
    color: $cor-texto-1;
    line-height: 1.1;
    //text-transform: uppercase;
    font-size: 32px;
    font-weight: 700;

    @include breakpoint($mobile-m) {
        font-size: 24px !important;
        font-weight: 600;
    }

    /*
    @include breakpoint($tablet-m) {
        font-size: 22px !important;
        font-weight: 600;
    }

    @include breakpoint($mobile-m) {
        font-size: 20px !important;
        font-weight: 700;
    }
    */
}

.subtitulo {
    font-family: $fonte-1;
    color: $cor-texto-1;
    line-height: 1.1;
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 500;

    @include breakpoint($mobile-m) {
        font-size: 24px !important;
        font-weight: 600;
    }

    /*
    @include breakpoint($tablet-m) {
        font-size: 22px !important;
        font-weight: 600;
    }

    @include breakpoint($mobile-m) {
        font-size: 20px !important;
        font-weight: 700;
    }
    */
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}