.modal_container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 2000;
    display: none;
    justify-content: center;
    align-items: center;

    &.active {
        display: flex;
    }

    .modal_overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .5);
        z-index: 1;
    }

    .modal_close {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 34px;
        height: 34px;
        border-radius: 100%;
        background: $branco;
        cursor: pointer;
        border: 2px solid $branco;

        .fas {
            color: $cor-4;
            font-size: 30px;
        }
    }

    .modal_content {
        z-index: 2;
        background: white;
        width: 550px;
        max-width: 94%;
        padding: 40px;
        position: relative;
        border-radius: 5px;

        @include breakpoint($mobile-l) {
            padding: 15px;
        }

        &.active {
            animation: exibeModal .3s;
        }

        &.modal_lateral {
            border-radius: 0px;

            &.active {
                animation: exibeMenu .3s;
            }
        }

        /*
        &.modal_central {
            max-height: 97%;
            overflow-y: auto;
            padding: 20px 18px 10px;

            .modal_close {
                top: 10px;
                right: 10px;
            }

            h2 {
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                display: block;
                padding-bottom: 10px;
                border-bottom: 1px solid $cor-05;
                margin-bottom: 15px;
                color: $cor-05;
                padding-right: 25px;
            }
        }
        */

        /* DEFINIÇÕES GERAIS */
        .botao_conexao {
            width: 700px;
            max-width: 100%;
            margin-bottom: 10px;
            border-radius: 4px;
            display: inline-block;
            padding: 15px;
            font-size: 18px;

            @include breakpoint($mobile-l) {
                font-size: 14px;
                font-weight: normal;
                letter-spacing: normal;
                margin-bottom: 5px;
                padding: 10px;
            }

            .fa,
            .fab {
                margin-right: 20px;
                font-weight: normal;
            }

            &.facebook {
                background-color: #4f6da9;
                color: $branco;
                border: 0;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
            }

            &.google {
                background: #d50f25;
                color: $branco;
                border: 0;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
            }
        }

        form {
            margin-top: 25px;

            &.form_orcamento {
                label {
                    color: $cor-3;
                }
            }

            &.form_reserva {
                label {
                    color: $cor-1;
                }
            }
        }

        input,
        select {
            @include style_input;
            display: block;
            width: 100%;
            border-radius: 4px;
            border: 1px solid #ede7d7;
            background-color: #ffffff;

            @include breakpoint($mobile-l) {
                height: 35px;
            }
        }

        label {
            display: block;
            text-transform: uppercase;
            font-size: 12px;

            @include breakpoint($mobile-l) {
                font-size: 14px;
            }
        }

        .modal_titulo {
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 1px;

            &.verde {
                color: $cor-3;
            }

            &.vermelho {
                color: $cor-1;
            }
        }

        .modal_subtitulo {
            font-weight: bold;
            font-size: 24px;
            line-height: 1.2;
        }

        .modal_endereco {
            font-size: 14px;
            margin: 15px 0;

            .fas {
                width: 20px;
                color: $cor-05;
            }
        }

        .botao_cheio,
        .botao_vazio {
            width: 100%;
        }

        .separador {
            text-align: center;
            display: block;
            width: 100%;
            color: $cor-1;
            font-size: 25px;
            font-weight: 800;
            letter-spacing: 1px;
            margin: 10px 0;

            @include breakpoint($mobile-l) {
                margin: 5px 0;
                font-size: 20px;
            }
        }

        .obs {
            display: block;
            margin-top: 15px;

            p {
                font-size: 14px;
            }

            @include breakpoint($mobile-l) {
                margin-top: 5px;

                p {
                    font-size: 12px;
                }
            }
        }

        .esqueceu {
            text-align: center;
            display: block;
            margin-top: 10px;
            cursor: pointer;
        }
    }

    /* MODAL LATERAL */
    &.container_lateral {
        justify-content: flex-end;
        align-items: stretch;

        .menu_close {
            position: absolute;
            top: 10px;
            right: 15px;
            width: 34px;
            height: 34px;
            border-radius: 100%;
            background: $branco;
            cursor: pointer;
            border: 2px solid $branco;

            @include breakpoint($mobile-l) {
                top: 5px;
                right: 10px;
            }

            .fas {
                color: $cor-1;
                font-size: 30px;
            }
        }

        .modal_content {
            padding: 20px;
            width: 300px;
            max-width: 95%;

            @include breakpoint($mobile-l) {
                padding: 10px 20px;
            }

            .subtitulo {
                font-weight: bold;
                margin-bottom: 20px;
                color: $cor-1;
                margin-top: 5px;

                @include breakpoint($mobile-l) {
                    margin-bottom: 10px;
                }
            }

            ul {
                .link {
                    a {
                        display: block;
                        width: 100%;
                        padding: 10px 0;
                        text-transform: uppercase;
                        color: $cor-03;

                        &:hover {
                            color: $cor-1;
                        }

                        &.ativo {
                            font-weight: bold;
                            color: $cor-1;
                        }
                    }
                }

                .botao {

                    .botao_cheio,
                    .botao_vazio {
                        margin-top: 15px;
                        width: 92%;
                    }
                }
            }
        }
    }
}

@keyframes exibeModal {
    from {
        opacity: 0;
        transform: translate3d(0, -60px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes exibeMenu {
    from {
        opacity: 1;
        transform: translate3d(400px, 0, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}