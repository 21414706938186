/*
= HTML Headings
= https://www.w3schools.com/html/html_headings.asp
===================================================*/

h1 {}

h2 {}

h3 {}

h4 {}

h5 {}

h6 {}

/*
= HTML Paragraphs
= https://www.w3schools.com/html/html_paragraphs.asp
=====================================================*/

p {
    color: $cor-texto-1;
    font-family: $fonte-1;
    margin: 10px 0;
    font-size: 17px;
    line-height: 22px;
    font-weight: 400;
    a {}
    span {}
    br {}
    cite {}
}

/*
= HTML Quotation and Citation Elements
= https://www.w3schools.com/html/html_quotation_elements.asp
=============================================================*/

q {}

blockquote {}

abbr {}

bdo {}

address {}

/*
= HTML Formatting Elements
= https://www.w3schools.com/html/html_formatting.asp
=====================================================*/

a {
    text-decoration: none;
}

b {
    font-weight: bold;
}

strong {
    font-weight: bold;
}

i {
    font-style: italic;
}

em {}

mark {}

small {}

del {}

ins {}

sub {}

sup {}

/*
= HTML Lists
= https://www.w3schools.com/html/html_lists.asp
================================================*/

ul {
    li {}
    dl {}
    dt {}
    dd {}
}

ol {
    li {}
    dl {}
    dt {}
    dd {}
}

/*
= HTML Images
= https://www.w3schools.com/html/html_images.asp
=================================================*/

img {}

map {}

area {}

picture {}

figure {
    figcaption {}
}

/*
= HTML Tables
= https://www.w3schools.com/html/html_tables.asp
=================================================*/

table {
    caption {}
    colgroup {}
    col {}
    thead {}
    tbody {}
    tfoot {}
    tr {
        th {}
        td {}
    }
}

/*
= HTML Computer Code Elements
= https://www.w3schools.com/html/html_computercode_elements.asp
================================================================*/

code {}

kbd {}

samp {}

var {}

pre {}

iframe {}

hr {}