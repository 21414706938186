.botao_cheio,
.button_full {
    padding: 10px 25px 7px;
    text-align: center;
    display: inline-block;
    border-radius: 5px;
    cursor: pointer;
    border: 0;
    font-size: 12px;
    font-weight: 600;
    font-family: $fonte-1;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include breakpoint($mobile-m) {
        padding: 10px 15px 7px;
    }

    .fa,
    .fab,
    .fas {
        font-size: 18px;
        vertical-align: text-bottom;
        margin-right: 5px;
        top: -3px;
        position: relative;
    }

    &.btn_branco {
        background: $branco;
        color: $cor-1;

        &:hover {
            background: lighten($preto, 95%);
            @include transition(all .65s ease-in-out);
        }
    }

    &.btn_vermelho {
        background: $cor-1;
        color: $branco;

        &:hover {
            background: darken($cor-1, 15%);
            @include transition(all .65s ease-in-out);
        }
    }
}

.botao_vazio,
.button_empty {
    padding: 10px 25px 7px;
    text-align: center;
    display: inline-block;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    font-family: $fonte-1;
    text-transform: uppercase;
    letter-spacing: 1px;

    @include breakpoint($mobile-m) {
        padding: 10px 15px 7px;
    }

    .fa,
    .fab,
    .fas {
        font-size: 18px;
        vertical-align: text-bottom;
        margin-right: 5px;
    }

    &.btn_branco {
        color: $branco;
        border: 2px solid $branco;

        &:hover {
            background: rgba(255, 255, 255, 0.3);
            @include transition(all .65s ease-in-out);
        }
    }

    &.btn_vermelho {
        color: $cor-1;
        border: 2px solid $cor-1;

        &:hover {
            background: lighten($cor-1, 30%);
            @include transition(all .65s ease-in-out);
        }
    }

    &.btn_cinza {
        color: $cor-05;
        border: 2px solid $cor-05;

        &:hover {
            background: rgba(0, 0, 0, 0.05);
            @include transition(all .65s ease-in-out);
        }
    }

    &.btn_verde {
        color: $cor-3;
        border: 2px solid $cor-3;

        &:hover {
            background: rgba(40, 167, 69, 0.3);
            @include transition(all .65s ease-in-out);
        }
    }
}