/* Thumbs */

.fancybox-thumbs {
    background: #fff;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 2px 2px 4px 2px;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    width: 212px;
    z-index: 99995;
}

.fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
    display: block;
}

.fancybox-show-thumbs .fancybox-inner {
    right: 212px;
}

.fancybox-thumbs > ul {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.fancybox-thumbs-x > ul {
    overflow: hidden;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar {
    width: 7px;
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

.fancybox-thumbs-y > ul::-webkit-scrollbar-thumb {
    background: #2a2a2a;
    border-radius: 10px;
}

.fancybox-thumbs > ul > li {
    backface-visibility: hidden;
    cursor: pointer;
    float: left;
    height: 75px;
    margin: 2px;
    max-height: calc(100% - 8px);
    max-width: calc(50% - 4px);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 100px;
}

.fancybox-thumbs-loading {
    background: rgba(0, 0, 0, .1);
}

.fancybox-thumbs > ul > li {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.fancybox-thumbs > ul > li:before {
    border: 4px solid #4ea7f9;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all .2s cubic-bezier(.25, .46, .45, .94);
    z-index: 99991;
}

.fancybox-thumbs .fancybox-thumbs-active:before {
    opacity: 1;
}

/* Styling for Small-Screen Devices */

@media all and (max-width: 800px) {
    .fancybox-thumbs {
        width: 110px;
    }
    .fancybox-show-thumbs .fancybox-inner {
        right: 110px;
    }
    .fancybox-thumbs > ul > li {
        max-width: calc(100% - 10px);
    }
}
