.text_style {
	font-weight: 400;
	font-size: 16px;
	line-height: 140%;
	margin: 5px 0;

	@include breakpoint($mobile_grande) {}
}

.ckeditor {
	font-size: 18px;
	font-weight: 400;
	line-height: 1.4;
	letter-spacing: 1px;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@extend .text_style;
		font-family: $fonte-1;
		font-weight: 700;
		margin: 1.4rem 0;
	}

	p {
		@extend .text_style;
		font-family: $fonte-1;
		margin: 15px 0;

		@include breakpoint($mobile-l) {
			font-size: 14px;
		}
	}

	h1 {
		font-size: 1.5rem;
	}

	h2 {
		font-size: 1.4rem;
	}

	h3 {
		font-size: 1.3rem;
	}

	h4 {
		font-size: 1.2rem;
	}

	h5 {
		font-size: 1.1rem;
	}

	h6 {
		font-size: 1rem;
	}

	ul,
	ol {
		padding-left: 30px;
		margin: 25px 0;

		li {
			@extend .text_style;
			margin: 5px 0;
			list-style-type: disc;
		}
	}

	ol {
		li {
			list-style-type: decimal;
		}
	}

	a {
		color: $cor-1;
		font-weight: bold;
		text-decoration: underline;
	}

	em {
		font-style: italic;
	}

	strong {
		font-weight: bold;
	}

	blockquote,
	pre {
		font-style: italic;
		font-family: $fonte-1;
		color: $cor-03;
		background: #eee;
		padding: 10px;
		border: 1px solid #aaa;

		p {
			&:first-child {
				margin-top: 0;

			}

			&:last-child {
				margin-bottom: 0;

			}
		}
	}

	img {
		display: block;
		width: auto;
		max-width: 100%;
		text-align: center;
		margin: 40px auto;
	}

	iframe {
		width: 100% !important;
		height: 100% !important;
		min-height: 445px;

		@include breakpoint($tablet_grande) {
			min-height: 340px;
		}

		@include breakpoint($tablet) {
			min-height: 395px;
		}

		@include breakpoint($mobile) {
			min-height: 165px;
		}
	}

	// iframe
}

// ckeditor