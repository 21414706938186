/*==================================================
=                       FIXES                      =
===================================================*/

select,
article,
section,
header,
aside,
footer,
textarea,
input,
h1,
h2,
h3,
h4,
h5,
h6,
a,
div,
ul,
li {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

iframe[name="google_conversion_frame"] {
    height: 0!important;
    overflow: hidden;
    display: block;
}

pre {
    z-index: 2;
}

img[data-pin-nopin="true"] {
    display: none;
}