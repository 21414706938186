/*====================================
=   SUMÁRIO
=   01. INICIAL
=   02. INSTITUCIONAL
=   03. MAPA DE PLACAS
=   04. SOLUÇÕES
=   05. SOLUÇÕES INTERNA
=   06. CLIENTES
=   07. FALE CONOSCO
=   08. ERRO
======================================
=    01. INICIAL 
======================================*/

body.inicial {
    .wrap_intro {
        height: 600px;
        display: flex;
        align-items: center;
        @include filtrograd(#FFECAA, #FF6500, 0.8, bottom);

        @include breakpoint($tablet-s) {

            height: auto;
            align-items: flex-end;
            padding: 180px 0 80px;
        }

        .layout {
            text-align: center;
            position: relative;
            z-index: 2;

            h1 {
                text-transform: uppercase;
                font-size: 48px;
                line-height: 1.2;
                font-weight: 300;

                @include breakpoint($tablet-s) {
                    font-size: 38px;
                }

                @include breakpoint($mobile-m) {
                    font-size: 28px;
                }

                .bold {
                    font-weight: bold;
                }

                .e_comercial {
                    font-weight: bold;
                    font-size: 36px;
                    font-weight: bold;
                    color: $cor-1;
                }

            }
        }
    }

    .wrap_destaques {
        padding: 0 0 100px 0;
        margin-top: -100px;
        position: relative;
        z-index: 2;

        @include breakpoint($tablet-s) {
            padding: 60px 0;
            margin-top: 0px;
        }

        .layout {
            display: flex;
            justify-content: center;

            h2 {}

            .box_destaques {
                display: flex;
                text-align: center;
                justify-content: center;
                width: 1000px;
                -webkit-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.04);
                -moz-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.04);
                box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
                background: $branco;

                @include breakpoint($mobile-l) {
                    flex-wrap: wrap;
                }

                .destaque {
                    padding: 40px 60px;
                    width: 50%;

                    &:first-child {
                        border-right: 1px solid #f4f4f4;
                    }

                    @include breakpoint($mobile-l) {
                        width: 100%;

                        &:first-child {
                            border-right: 0;
                            border-bottom: 1px solid #f4f4f4;
                        }
                    }

                    @include breakpoint($mobile-s) {
                        padding: 30px 15px;
                    }

                    figure {

                        img {}

                        figcaption {

                            h2 {
                                margin: 10px 0;
                                font-weight: bold;
                                font-size: 20px;
                            }

                            p {}

                            .botao_vazio {
                                margin-top: 10px;
                            }
                        }
                    }
                }

                .direita {

                    figure {

                        img {}

                        figcaption {

                            h2 {}

                            p {}

                            .botao_vazio {}
                        }
                    }
                }
            }
        }
    }

    .wrap_cidades {
        @include filtro($branco, 0.8);
        padding: 100px 0;

        @include breakpoint($tablet) {
            padding: 40px 0;
        }


        .layout {
            position: relative;
            z-index: 2;
            text-align: center;

            h2 {
                margin-bottom: 50px;
            }

            .lista_cidades {
                display: flex;
                justify-content: space-between;

                @include breakpoint($tablet) {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                .item {
                    text-align: center;
                    border-radius: 5px;
                    background: $branco;
                    width: 18%;
                    height: 230px;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    -webkit-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.02);
                    -moz-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.02);
                    box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.02);
                    @include transition(all .35s ease-in-out);

                    .fa,
                    .fas,
                    .fab {
                        font-size: 70px;
                        color: $cor-1;
                    }

                    h3 {
                        margin-top: 15px;
                        font-weight: bold;
                        color: $cor-4;
                        font-size: 24px;
                    }

                    &:hover {
                        width: 20%;
                        background: $cor-1;

                        .fa,
                        .fas,
                        .fab {
                            font-size: 74px;
                            color: $branco;
                        }

                        h3 {
                            color: $branco;
                        }
                    }

                    @include breakpoint($tablet) {
                        width: 31%;
                        margin: 1%;

                        &:hover {
                            width: 31%;
                            margin: 1%;
                        }
                    }

                    @include breakpoint($mobile-l) {
                        height: 180px;

                        .fa,
                        .fas,
                        .fab {
                            font-size: 50px;
                        }

                        &:hover {

                            .fa,
                            .fas,
                            .fab {
                                font-size: 50px;
                            }
                        }

                    }

                    @include breakpoint($mobile-m) {
                        width: 100%;

                        &:hover {
                            width: 100%;
                        }
                    }
                }
            }

            .botao_cheio {
                margin-top: 50px;
            }
        }
    }

    .wrap_clientes {
        padding: 100px 0;

        .layout {
            text-align: center;

            h2 {}

            .carrossel_clientes {
                display: block;
                margin-top: 50px;

                .item {
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #f4f4f4;

                    img {
                        width: auto;
                        max-width: 80%;
                        display: block;
                        height: auto;
                        max-height: 80%;
                        opacity: 0.5;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                        @include transition(all .65s ease-in-out);
                    }

                    &:hover {
                        img {
                            opacity: 1;
                            -webkit-filter: grayscale(0%);
                            filter: grayscale(0%);
                        }
                    }
                }
            }
        }
    }
}

/*====================================
=    02. INSTITUCIONAL
======================================*/

body.institucional {
    .wrap_cabecalho {

        .layout {

            h1 {}

            p {}
        }
    }

    .wrap_sobre {
        padding: 100px 0;

        @include breakpoint($tablet) {
            padding: 40px 0;
        }

        .layout {
            display: flex;
            justify-content: space-between;

            @include breakpoint($tablet) {
                flex-wrap: wrap;
            }

            .coluna {
                width: 48%;

                @include breakpoint($tablet) {
                    width: 100%;

                    &:nth-child(1) {
                        margin-bottom: 30px;
                    }
                }

                h2 {}

                .ckeditor {

                    p {}
                }

                .botao_vazio {
                    margin-top: 20px;
                }

                .galeria_fotos {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .album {
                        width: 32.5%;
                        margin-bottom: 7px;
                        height: 200px;
                        position: relative;
                        @include filtrograd(transparent, $preto, 0.8, bottom);

                        @include breakpoint($mobile-l) {
                            display: none;

                            &:first-child {
                                display: block;
                                width: 100%;
                            }
                        }

                        .texto {
                            z-index: 2;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: block;

                            .nome {}

                            .botao_visualizar {
                                width: 100%;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: absolute;

                                .fa,
                                .fas,
                                .fab,
                                .far {
                                    opacity: 0;
                                    margin-top: 40px;
                                    font-size: 30px;
                                    color: $branco;
                                    @include transition(all .35s ease-in-out);
                                }

                                &:hover {

                                    .fa,
                                    .fas,
                                    .fab,
                                    .far {
                                        opacity: 1;
                                        margin-top: 0px;
                                        font-size: 30px;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    .wrap_valores {
        padding: 70px 0;
        @include filtro($branco, 0.8);

        @include breakpoint($tablet) {
            padding: 40px 0;
        }

        .layout {
            z-index: 2;

            .lista_itens {
                display: flex;
                justify-content: space-between;

                @include breakpoint($tablet) {
                    flex-wrap: wrap;
                }

                .item {
                    width: 31%;
                    text-align: center;

                    @include breakpoint($tablet) {
                        width: 100%;
                        margin-bottom: 20px;
                    }

                    .icon {
                        font-size: 30px;
                        margin-bottom: 15px;
                        display: block;
                        color: $cor-1;
                    }

                    .minititulo {
                        font-weight: 700;
                        font-size: 20px;
                        text-transform: uppercase;
                        //letter-spacing: 6px;

                    }

                    p,
                    ul {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

/*====================================
=    03. MAPA DE PLACAS
======================================*/

body.mapa-de-placas {
    .wrap_cabecalho {

        .layout {

            h1 {}

            p {}
        }
    }

    .wrap_filtro {
        padding: 40px 0;
        background: lighten($cor-2, 25%);

        .layout {
            text-align: center;

            h2 {}

            p {}

            .filtro_local {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;

                @include breakpoint($mobile-l) {
                    flex-wrap: wrap;
                }

                .third {
                    width: 32%;

                    @include breakpoint($mobile-l) {
                        width: 100%;
                    }

                    label {
                        display: block;
                        text-transform: uppercase;
                        font-size: 12px;
                    }

                    select {
                        width: 100%;
                        @include style_input;
                        border-color: $branco;
                    }

                    input {
                        border-color: $branco;

                    }
                }
            }
        }
    }

    .wrap_placas {
        padding: 40px 0 10px;

        .layout {

            .lista_placas {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .item {
                    position: relative;
                    width: 30%;
                    margin: 0 1.5%;
                    margin-bottom: 30px;
                    border-radius: 5px;
                    -webkit-box-shadow: 0px 0px 5px 7px rgba(0, 0, 0, 0.01);
                    -moz-box-shadow: 0px 0px 5px 7px rgba(0, 0, 0, 0.01);
                    box-shadow: 0px 0px 5px 7px rgba(0, 0, 0, 0.01);
                    padding-bottom: 70px;

                    @include breakpoint($tablet) {
                        width: 48%;
                        margin: 0 1%;
                        margin-bottom: 30px;
                    }

                    @include breakpoint($mobile-l) {
                        width: 100%;
                        margin: 0 0;
                        margin-bottom: 30px;
                    }

                    .foto {
                        height: 200px;
                        border-radius: 5px 5px 0 0;
                        position: relative;

                        @include filtrograd(transparent, $preto, .7, bottom);

                        .categoria {
                            z-index: 2;
                            display: block;
                            position: absolute;
                            left: 10px;
                            top: 10px;
                            padding: 5px 10px;
                            color: $branco;
                            font-weight: bold;
                            border-radius: 5px;
                            opacity: 0.8;
                            font-size: 14px;

                            &.disponivel {
                                background: $cor-3;
                            }

                            &.indisponivel {
                                background: $cor-1;
                            }
                        }
                    }

                    .conteudo {
                        padding: 20px 20px 10px;
                        border: 1px solid #f4f4f4;
                        border-top: 0;
                        border-bottom: 0;

                        .título {
                            font-weight: bold;
                            font-size: 18px;
                        }

                        .endereco {
                            font-size: 14px;
                            margin: 15px 0;

                            .fas {
                                width: 20px;
                                color: $cor-05;
                            }
                        }

                        .descricao {
                            font-size: 14px;

                            .fas {
                                width: 20px;
                                color: $cor-05;
                            }
                        }

                        .tempo {
                            margin-top: 20px;
                            text-transform: uppercase;
                            font-size: 12px;
                            letter-spacing: 2px;

                            .far {
                                width: 20px;
                                font-size: 14px;
                            }

                            &.ate {
                                color: $cor-3;
                                font-weight: bold;
                            }

                            &.apartir {
                                color: $cor-1;
                                font-weight: bold;
                            }

                            .date {
                                display: block;
                                color: $cor-4;
                                font-weight: normal;
                            }
                        }
                    }

                    .ctas {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        border: 1px solid #f4f4f4;
                        border-top: 0;
                        border-radius: 0 0 5px 5px;
                        padding: 5px 20px 20px;

                        .botao_vazio,
                        .botao_cheio {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .wrap_mapa {}
}

/*====================================
=    04. SOLUÇÕES
======================================*/

body.solucoes {
    .wrap_cabecalho {

        .layout {

            .titulo {}

            .descricao_pagina {}
        }
    }

    .wrap_solucoes {
        padding: 60px 0 30px;

        @include breakpoint($tablet) {
            padding: 40px 0 30px;
        }

        .layout {
            width: 1000px;

            .subtitulo {}

            .lista_solucoes {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .item {
                    width: 49%;
                    height: 300px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin-bottom: 30px;
                    border-radius: 10px;
                    overflow: hidden;
                    @include filtrograd(#FFECAA, #FF6500, 0.8, bottom);

                    @include breakpoint($mobile-l) {
                        width: 100%;
                    }

                    .texto {
                        position: relative;
                        z-index: 2;
                        text-align: center;
                        width: 80%;

                        h2 {}

                        p {}

                        .botao_vazio {
                            margin-top: 20px;
                        }
                    }
                }

                .botao_vazio {}
            }
        }
    }
}

/*====================================
=    05. SOLUÇÕES INTERNA
======================================*/

body.solucoes-interna {
    .wrap_cabecalho {

        .layout {

            .titulo {}

            .descricao_pagina {}
        }
    }

    .wrap_solucao {
        padding: 80px 0;

        @include breakpoint($tablet) {
            padding: 40px 0;
        }

        .layout {
            display: flex;
            justify-content: space-between;
            width: 1100px;

            @include breakpoint($tablet) {
                flex-wrap: wrap;
            }

            .coluna {
                width: 48%;

                @include breakpoint($tablet) {
                    width: 100%;

                    &:nth-child(2) {
                        margin-top: 30px;
                    }
                }

                .ckeditor {

                    p {}
                }

                .botao_vazio {

                    .fas {}
                }

                form {
                    background: $cor-1;
                    padding: 40px;
                    border-radius: 10px;

                    .subtitulo,
                    p {
                        color: $branco;
                        text-align: center;
                    }

                    p {
                        margin: 20px 0;
                    }

                    label {
                        color: $branco;
                        display: block;
                        text-transform: uppercase;
                        font-size: 12px;
                    }

                    input {
                        border-color: $branco;
                    }
                }
            }
        }
    }

    .wrap_galeria {
        padding: 60px 0 50px;
        background: lighten($cor-2, 25%);

        @include breakpoint($tablet) {
            padding: 40px 0 30px;
        }

        .layout {

            .galeria_fotos {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .album {
                    width: 24.5%;
                    margin-bottom: 7px;
                    height: 200px;
                    position: relative;
                    @include filtrograd(transparent, $preto, 0.8, bottom);

                    @include breakpoint($mobile-l) {
                        display: none;

                        &:first-child {
                            display: block;
                            width: 100%;
                        }
                    }

                    .texto {
                        z-index: 2;
                        position: relative;
                        width: 100%;
                        height: 100%;
                        display: block;

                        .nome {}

                        .botao_visualizar {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: absolute;

                            .fa,
                            .fas,
                            .fab,
                            .far {
                                opacity: 0;
                                margin-top: 40px;
                                font-size: 30px;
                                color: $branco;
                                @include transition(all .35s ease-in-out);
                            }

                            &:hover {

                                .fa,
                                .fas,
                                .fab,
                                .far {
                                    opacity: 1;
                                    margin-top: 0px;
                                    font-size: 30px;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}

/*====================================
=    06. CLIENTES
======================================*/

body.clientes {
    .wrap_cabecalho {

        .layout {

            h1 {}

            p {}
        }
    }

    .wrap_clientes {
        padding: 60px 0;

        @include breakpoint($tablet) {
            padding: 40px 0;
        }

        .layout {

            .lista_clientes {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .item {
                    width: 17%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 120px;
                    border: 1px solid #f4f4f4;
                    //padding: 20px;
                    margin-bottom: 15px;

                    @include breakpoint($tablet-l) {
                        width: 24%;
                    }

                    @include breakpoint($mobile-l) {
                        width: 49%;
                    }

                    @include breakpoint($mobile-m) {
                        width: 100%;
                    }

                    img {
                        display: block;
                        width: auto;
                        height: auto;
                        max-width: 80%;
                        max-height: 80%;
                    }
                }
            }
        }
    }
}

/*====================================
=    07. FALE CONOSCO
======================================*/

body.fale-conosco {
    .wrap_contatos {
        padding: 230px 0 50px;

        background: lighten($cor-2, 25%);

        @include breakpoint($tablet) {
            padding: 150px 0 30px;
        }

        .layout {
            display: flex;
            justify-content: space-between;
            width: 1100px;

            @include breakpoint($tablet) {
                flex-wrap: wrap;
            }

            .coluna {
                width: 48%;

                @include breakpoint($tablet) {
                    width: 100%;
                    text-align: center;

                    &:nth-child(1) {
                        order: 2;
                    }

                    &:nth-child(2) {
                        order: 1;
                        margin-bottom: 30px;
                    }
                }

                .titulo {
                    margin-top: 50px;

                    @include breakpoint($tablet) {
                        margin-top: 0;
                    }
                }

                p {}

                .dados_contato {

                    p {
                        margin-top: 20px;

                        .fa,
                        .fas,
                        .fab {
                            width: 25px;
                            color: $branco;
                        }
                    }
                }

                .redes_sociais {
                    margin-top: 50px;


                    ul {

                        li {
                            display: inline-block;

                            a {
                                font-size: 30px;
                                margin: 3px;
                                color: $branco;

                                &:hover {
                                    color: $cor-1;

                                }
                            }
                        }
                    }
                }

                form {

                    padding: 40px;
                    background: $branco;
                    border-radius: 10px;


                    label {
                        color: $cor-2;
                        text-transform: uppercase;
                        font-size: 12px;
                    }

                    input {}

                }
            }
        }
    }

    .wrap_mapa {

        .mapa {
            display: block;
            width: 100%;
            height: 400px;
        }
    }
}

/*====================================
=    08. ERRO
======================================*/

body.erro {
    .wrap_erro {
        padding: 250px 0 150px;
        background: lighten($cor-2, 25%);

        .layout {
            text-align: center;
            width: 500px;
            max-width: 100%;

            .titulo {
                font-size: 70px;
                text-transform: uppercase;
                color: $cor-1;
                margin-bottom: 20px;
            }

            .subtitulo {}

            .descricao_pagina {}
        }
    }
}