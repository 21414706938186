@charset 'UTF-8';
/*=====================================================
=  MAIN.SCSS             							=
=                                                 	=
=  Arquivo principal que engloba todo o   			=
=  estilo do projeto e a partir dele é gerado o    	=
=  arquivo de CSS chamado no INDEX, este gerado    	=
=  através de um compilador.                       	=
=                                                  	=
=  Autora: Victoria Moura                          	=
=  URL da Autora: www.victoriamoura.com            	= 
======================================================= 
=    APOIO 
==========================*/

@import 'helpers/resets';
@import 'helpers/fixes';
@import 'helpers/flex';
@import 'helpers/grid';
@import 'helpers/mixins';
//=========================
//    MÓDULOS 
//=========================

//@import 'modules/susy/sass/susy-prefix';
@import '../../node_modules/bourbon/core/bourbon';
@import '../../node_modules/breakpoint-sass/stylesheets/breakpoint';
/*========================= 
=    FONTS  
==========================*/

@import url('https://fonts.googleapis.com/css?family=Raleway:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
//@import 'fonts/fontawesome/fontawesome';
//@import 'fonts/fontawesome/brands';
//@import 'fonts/fontawesome/solid';
//@import 'fonts/fontawesome/regular';

@import 'fonts/fontawesome5/fontawesome';
@import 'fonts/fontawesome5/brands';
@import 'fonts/fontawesome5/solid';
@import 'fonts/fontawesome5/regular';
/*========================= 
=    PLUGINS 
==========================*/

@import 'plugins/owl-carousel/owlcarousel';
//@import 'plugins/bx-slider/bxslider';
@import 'plugins/fancy-box/core';
@import 'plugins/fancy-box/fullscreen';
@import 'plugins/fancy-box/share';
@import 'plugins/fancy-box/slideshow';
@import 'plugins/fancy-box/thumbs';
/*=========================
=    COMPONENTS
==========================*/

@import 'parts/definitions';
@import 'parts/classes';
@import 'parts/formats';
@import 'parts/buttons';
@import 'parts/forms';
@import 'parts/ckeditor';
@import 'parts/modals';
//@import 'parts/choose';
//@import 'parts/share';
//@import 'parts/comments';
/*=========================
=    PROJECT
==========================*/

@import 'project/recurrent';
@import 'project/body';