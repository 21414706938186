/* 
    GRID DE GALERIA DE ITENS
    Define a quantidade e tamanho de colunas do grid e aplica os breakpoints para o responviso.
*/

// CONTAINER DA GALERIA
@mixin flex-grid-container ($direction: row, $wrap: wrap, $justify: flex-start, $align-items: flex-start, $align-content: flex-start) {
    @include inline-flex;
    flex-flow: $direction $wrap;
    justify-content: $justify;
    align-items: $align-items;
    align-content: $align-content;
}

// ITEM DA GALERIA
@mixin flex-grid-itens($colunas, $margin-bottom: 15px, $break1: $tablet-m, $break2: $tablet-s, $break3: $mobile-m) {
    // Calculo de tamanho de coluna e margem
    // GRID COM DUAS COLUNAS
    @if $colunas==2 {
        width: 49%;
        margin: 1%;
        /* reseta os breakpoints caso existam*/
        &:nth-child(3n) {
            margin-right: 1%;
        }
        &:nth-child(3n+1) {
            margin-left: 1%;
        }
        &:nth-child(2n) {
            margin-right: 0;
        }
        &:nth-child(2n+1) {
            margin-left: 0;
        }
        @include breakpoint($break1) {
            width: 100%;
            flex-direction: column;
            margin: 0 !important;
            margin-bottom: $margin-bottom !important;
        }
    } // GRID COM DUAS COLUNAS
    @if $colunas==3 {
        width: 32%;
        margin: 1%;
        &:nth-child(3n) {
            margin-right: 0;
        }
        &:nth-child(3n+1) {
            margin-left: 0;
        }
        @include breakpoint($break1) {
            width: 100%;
            flex-direction: column;
            margin: 0;
            margin-bottom: $margin-bottom;
        }
    } // GRID COM QUATRO COLUNAS
    @if $colunas==4 {
        width: 24%;
        margin: 0.66%;
        &:nth-child(4n) {
            margin-left: 0.66%;
            margin-right: 0;
        }
        &:nth-child(4n+1) {
            margin-left: 0;
            margin-right: 0.66%;
        }
        @include breakpoint($break1) {
            width: calc((100% / 2) - 2%);
        }
        @include breakpoint($break2) {
            width: 100%;
            margin: 0;
            margin-bottom: $margin-bottom;
            flex-direction: column;
        }
    } // GRID COM CINCO COLUNAS
    @if $colunas==5 {
        &:nth-child(5n) {
            margin-right: 0;
        }
        &:nth-child(5n+1) {
            margin-left: 0;
        }
        @include breakpoint($break1) {
            width: calc((100% / 2) - 2%);
        }
        @include breakpoint($break2) {
            width: 100%;
            margin: 0;
            margin-bottom: $margin-bottom;
            flex-direction: column;
        }
    } // GRID COM SEIS COLUNAS
    @if $colunas==6 {
        &:nth-child(6n) {
            margin-right: 0;
        }
        &:nth-child(6n+1) {
            margin-left: 0;
        }
        @include breakpoint($break1) {
            width: calc((100% / 3) - 2%);
        }
        @include breakpoint($break2) {
            width: calc((100% / 2) - 2%);
        }
        @include breakpoint($break3) {
            width: 100%;
            margin: 0;
            margin-bottom: $margin-bottom;
            flex-direction: column;
        }
    }
}

/* 
    LINHA DE ITENS SEM QUEBRA
    Define quantas colunas de um total o span assume sobre a divisão do elemento pai.
*/

// CONTAINER DE LINHA
@mixin flex-line-container ($direction: row, $wrap: nowrap, $justify: space-between, $align-items: stretch, $align-content:stretch) {
    @include inline-flex;
    flex-flow: $direction $wrap;
    justify-content: $justify;
    align-items: $align-items;
    align-content: $align-content;
}

// ITEM DE LINHA
@mixin flex-line-itens($order: 0, $grow: 0, $shrink: 1, $basis: auto, $align: stretch) {
    order: $order;
    align-self: $align;
    flex: $grow $shrink $basis;
}

/* 
    ITENS EM SPANS
    Define quantas colunas de um total o span assume sobre a divisão do elemento pai.
*/

@mixin span($col, $total, $margin: 15px) {
    float: left;
    position: relative;
    display: inline-block;
    margin-right: $margin;
    width: calc( ( (100% / #{$total}) * #{$col}) - #{$margin});
}

/*
    ITEM PILOTO
    Define o tamanho de uma div e calcula o espaço restante para a outra em relação ao tamanho da div pai.
    O mixin deve ser usado para as duas divs informando qual é a piloto.
 */

@mixin piloto($piloto, $tam: 80%) {
    @if $piloto=='yes' {
        width: $tam;
    }
    @elseif $piloto=='no' {
        width: calc(100% - #{$tam});
    }
    @else {
        width: 100%;
    }
}