/*====================================
=    FORMULÁRIO
======================================*/

/*
    =   STYLE INPUT GLOBAL
    ===================== */

@mixin style_input {
    outline: none;
    cursor: pointer;
    width: 100%;
    height: 35px;
    padding: 0 20px;
    margin: 0 0 10px 0;
    color: lighten($preto, 70%);
    font-family: $fonte-1;
    // background: $cor-12;
    border: 1px solid $cor-05;
    border-radius: 5px;
    @include transition(all .55s ease-in-out);

    @include placeholder {
        opacity: 1 !important;
        color: lighten($preto, 70%);
    }

    &:active {
        //background: $cor-9;
    }

    &:focus {
        //border: 1px solid $cor-1;
        -webkit-box-shadow: inset 0px 0px 4px 0px $cor-2;
        -moz-box-shadow: inset 0px 0px 4px 0px $cor-2;
        box-shadow: inset 0px 0px 4px 0px $cor-2;
    }

    &:focus {}

    &:hover {}

    &:invalid {
        color: tint(red, 10%);
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.form,
form {

    /*
    = INPUT TEXTS
    ============== */
    #{$all-text-inputs} {
        // color, date, datetime, datetime-local
        // email, month, number, password, search
        // tel, text, time, url, week, not type, textarea
        @include style_input;
    }

    /* 
    = TEXTAREA
    =========== */
    textarea {
        height: 90px;
        resize: none;
        padding: 15px 20px;
    }

    /*
    = LABELS
    =========== */
    label {}

    /*
    = SELECT OPTION
    ================ */
    select {
        @include style_input;
        /*desabilida seta*/
        //-webkit-appearance: none;
        //-moz-appearance: none;

        &::-ms-expand {
            display: none;
        }

        option {
            padding: 10px 0;
        }
    }

    /*
    = OTHERS INPUTS
    ================ */
    input[type="file"] {
        @include style_input;
    }

    input[type="search"] {
        padding-left: 45px !important;
        background-image: url('../img/icones/ic_lupa.png');
        background-repeat: no-repeat;
        background-position: 20px center;
    }

    input[type="date"],
    .datepiker,
    .pikaday {
        padding-left: 45px !important;
        background-image: url('../img/icones/ic_calendar.png');
        background-repeat: no-repeat;
        background-position: 20px center;

        &::-webkit-datetime-edit-month-field,
        &::-webkit-datetime-edit-day-field,
        &::-webkit-datetime-edit-year-field {
            color: $cor-1;
        }

        &::-webkit-clear-button {
            font-size: 14px;
            height: 30px;
            margin-top: -5px;
            position: relative;
            margin-right: 5px;
            color: $cor-1;
        }

        &::-webkit-inner-spin-button {
            display: none;
        }

        &::-webkit-calendar-picker-indicator {
            font-size: 18px;
        }
    }

    input[type="checkbox"] {}

    input[type="radio"] {}

    input[type="submit"],
    button {
        border: 0;

        &:disabled {
            cursor: not-allowed;
        }

        .enable {}

        &[status="loading"] {
            background: rgba(255, 255, 255, 0.3);
            width: 100%;
            height: 100%;
            cursor: not-allowed;

            &:before {
                margin-right: 10px;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f110";
                -webkit-animation: spin 1s infinite linear;
            }

            @-webkit-keyframes spin {
                0% {
                    -webkit-transform: rotate(0deg);
                }

                100% {
                    -webkit-transform: rotate(360deg);
                }
            }
        }

        .enable {}
    }

    /*
    = OTHERS INPUTS
    ================ */
    //@include flex-grid-container;
    //width: 100%;
    .line {
        width: 100%;
        margin-bottom: 20px;
    }

    .full {
        width: 100%;
    }

    .half {
        //@include flex-grid-itens(2);
    }

    .third {
        //@include flex-grid-itens(3);
    }

    .fourth {
        //@include flex-grid-itens(4, $break1: $desktop-l, $break2: $tablet-l);
    }
}