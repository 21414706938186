/*====================================
=   SUMÁRIO
=   01. HEADER
=   02. FOOTER
=   03. SIDEBAR
======================================
=    01. HEADER
======================================*/

header {
    &.header_main {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 3;

        .layout {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo {
                display: block;

                a {
                    .logo_full {
                        width: 120px;
                        display: block;
                    }

                    .logo_icon {
                        display: none;
                    }
                }
            }

            .menu_desktop {
                display: flex;
                align-items: center;

                .link {
                    margin-right: 5px;

                    @include breakpoint($tablet) {
                        display: none;
                    }

                    a {
                        padding: 5px 10px;
                        display: block;
                        color: $cor-4;
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 500;
                    }

                    &:hover,
                    &.ativo {
                        a {
                            color: $cor-1;
                        }
                    }
                }

                .hamburguer {
                    display: none;

                    .fas {
                        font-size: 30px;
                    }

                    @include breakpoint($tablet) {
                        display: block;
                    }
                }

                .botao {
                    margin-left: 40px;

                    @include breakpoint($tablet-l) {
                        display: none;
                    }

                    .botao_cheio {}
                }
            }
        }

        &.scrollado {
            background: $branco;
            -webkit-box-shadow: 0px 10px 33px -17px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 10px 33px -17px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 33px -17px rgba(0, 0, 0, 0.1);

            .layout {
                padding: 5px 15px;

                .logo {
                    a {
                        .logo_full {
                            display: none;
                        }

                        .logo_icon {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

/*====================================
=    02. FOOTER
======================================*/

footer {
    &.footer_main {
        background: #f4f4f4;
        padding: 40px 0;

        .layout {
            display: flex;

            @include breakpoint($tablet) {
                flex-wrap: wrap;
            }

            .coluna {
                width: 24%;

                @include breakpoint($tablet) {
                    width: 32%;
                    padding: 1%;

                    &:nth-child(2) {
                        display: none;
                    }
                }

                @include breakpoint($tablet-l) {
                    width: 100%;
                    text-align: center;

                }

                .titulo_footer {
                    font-weight: bold;
                    font-size: 16px;
                    text-transform: uppercase;
                    margin-bottom: 15px;
                }

                figure {

                    img {}
                }

                p {
                    font-size: 14px;

                    .bold {
                        font-weight: bold;
                    }
                }

                .lista_social {
                    margin-top: 15px;

                    li {
                        display: inline-block;

                        a {
                            color: $cor-04;
                            margin: 5px 5px;

                            .fab {
                                font-size: 24px;
                            }

                            &:hover {
                                color: $cor-2;
                            }
                        }

                    }
                }

                .menu_footer {
                    li {
                        padding: 5px 0;

                        a {
                            font-size: 14px;

                            &:hover {
                                color: $cor-2;
                            }
                        }
                    }
                }

                form {
                    input {}
                }
            }
        }
    }
}

/*====================================
=    03. CABEÇALHO
======================================*/

.wrap_cabecalho {
    padding: 200px 0 50px;
    background: #f4f4f4 url('../img/bgs/bg1.png');
    //border-bottom: 3px solid $cor-2;
    text-align: center;
    -webkit-box-shadow: inset 0px -10px 19px -7px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: inset 0px -10px 19px -7px rgba(0, 0, 0, 0.04);
    box-shadow: inset 0px -10px 19px -7px rgba(0, 0, 0, 0.04);

    .layout {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        h1 {}


        .descricao_pagina {
            margin-top: 20px;
            width: 600px;
            max-width: 100%;
        }
    }
}

/*====================================
=    03. SIDEBAR
======================================*/

.blog_sidebar,
.page_sidebar {}

/*====================================
=    03. LISTA ITENS
======================================*/

.lista_itens {}


/*====================================
=    03. PAGINAÇÃO
======================================*/

.paginacao {}