/*=======================================
=   MIXINS EXTRAS
========================================*/

@mixin rounded($radius: 0.5em) {
    @each $vendor in $vendors {
        #{$vendor}border-radius: $radius;
    }
}

@mixin full {
    @include span (full);
    margin-left: auto!important;
    margin-right: auto!important;
    float: none!important;
}

@mixin clearfix {
    &:after {
        clear: both;
        content: "";
        display: block;
    }
}

@mixin placeholder {
     ::-webkit-input-placeholder {
        @content
    }
    :-moz-placeholder {
        @content
    }
    ::-moz-placeholder {
        @content
    }
    :-ms-input-placeholder {
        @content
    }
}


/*=======================================
=   MIXINS IMAGENS
========================================*/

@mixin foto {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}

@mixin imgcenter($height, $area) {
    display: block;
    height: $height;
    width: 100%;
    position: relative;
    img {
        width: auto;
        height: auto;
        max-width: $area;
        max-height: $area;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translateY(-50%)translateX(-50%));
    }
}


/*=======================================
=   MIXINS FILTROS
========================================*/

@mixin filtro($cor, $opacidade) {
    position: relative;
    &:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: $cor;
        opacity: $opacidade;
        content: " ";
        @include transition(all .65s ease-in-out);
    }
}

@mixin filtrograd($cor1, $cor2, $opacidade, $way) {
    position: relative;
    &:after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: $cor1;
        opacity: $opacidade;
        content: " ";
        @include transition(all .65s ease-in-out);
        background: -webkit-linear-gradient($way, $cor1, $cor2);
        background: -o-linear-gradient($way, $cor1, $cor2);
        background: -moz-linear-gradient($way, $cor1, $cor2);
        background: linear-gradient(to $way, $cor1, $cor2);
    }
}


/*=======================================
=   MIXINS MODAL
========================================*/

@mixin modalfull {
    top: 0;
    right: 0;
    left: initial;
    z-index: 9999;
    width: 100%;
    height: 100%;
    max-width: none;
    transform: initial;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    visibility: hidden;
}

@mixin modalbox($max-width) {
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: $max-width;
    z-index: 9999;
    opacity: 0;
    position: fixed;
    overflow: hidden;
    visibility: hidden;
    transform: translateY(-50%) translateX(-50%);
}

@mixin modalside($side, $width) {
    @if $side=='left' {
        left: -100%;
    }
    @if $side=='right' {
        right: -100%;
    }
    position: fixed;
    top: 0;
    width: $width;
    z-index: 9999;
    height: 100%;
    max-width: 100%;
    transform: initial;
    &.ativo {
        right: 0;
    }
}


/*=======================================
=   MIXINS TRANSITION
=   https://codepen.io/sawmac/pen/cayhK/
========================================*/

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}


/*=======================================
=   MIXINS TRANSFORM
=   https://codepen.io/sawmac/pen/qGudy
========================================*/

@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}

@mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
    @include transform(scale($scale));
}

@mixin translate ($x, $y) {
    @include transform(translate($x, $y));
}

@mixin skew ($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}

@mixin transform-origin ($origin) {
    moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}